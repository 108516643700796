/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    div: "div",
    strong: "strong"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 473px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 75.3012048192771%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAMEAQL/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAGtKODCMP/EABkQAAMAAwAAAAAAAAAAAAAAAAABAhETIf/aAAgBAQABBQIpzI33bktjo//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABUQAQEAAAAAAAAAAAAAAAAAABAR/9oACAEBAAY/AmP/xAAZEAADAQEBAAAAAAAAAAAAAAAAAREhQTH/2gAIAQEAAT8hzLhY70UFClS/FWUfp//aAAwDAQACAAMAAAAQow//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAcEAEBAAICAwAAAAAAAAAAAAABEQAhMWFBUXH/2gAIAQEAAT8QZIAs51i7VG3UXudZejrCKI+8u2XkNTKasfmf/9k='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"whole nine yards\"\n        title=\"whole nine yards\"\n        src=\"/static/e7319d29acbe9c13d97fb6f50cc4263f/8aa1e/fighter-plane2.jpg\"\n        srcset=\"/static/e7319d29acbe9c13d97fb6f50cc4263f/4d5fb/fighter-plane2.jpg 166w,\n/static/e7319d29acbe9c13d97fb6f50cc4263f/558f0/fighter-plane2.jpg 333w,\n/static/e7319d29acbe9c13d97fb6f50cc4263f/8aa1e/fighter-plane2.jpg 473w\"\n        sizes=\"(max-width: 473px) 100vw, 473px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })), "\n", React.createElement(_components.p, null, "Though most phrases are often thought of as antiquated, \"whole nine yards\" wasn’t actually seen in print until the early 1960’s."), "\n", React.createElement(_components.p, null, "There are a few competing theories as to the term's origins but perhaps the most plausible comes from WWII."), "\n", React.createElement(_components.p, null, "During pre-flight preparations combat planes would be outfitted with munitions."), "\n", React.createElement(_components.p, null, "In particular, 0.50 caliber ammo came in belts and would be fed into the machine gun of the plane - a full load measuring 27 feet or 9 yards."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "If a gunner unloaded all his ammunition, he would have given the enemy the \"whole nine yards\".")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
